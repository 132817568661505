import React from "react";
import '../../styles/assets/css/particle-theme.css';
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Regular() {
    const revoLight = `https://${window.location.hostname}/images/revo-light.svg`;
    const [ subMenuState, setSubMenuState ] = useState([false, false]); 
    const [ stateUpdate, setStateUpdate ] = useState(false)

    function handleSubmenu (e){
        let arr = subMenuState;
        if(e.target.name == 'api'){
            if(subMenuState[0]){
                arr[0] = false;
            }else{
                arr[0] = true;
            }
        }else if(e.target.name == 'exp'){
            if(subMenuState[1]){
                arr[1] = false;
            }else{
                arr[1] = true;
            }
        }else if(e.target.name == 'fau'){
            if(subMenuState[2]){
                arr[2] = false;
            }else {
                arr[2] = true;
            }
        }
        stateUpdate ? setStateUpdate(false) : setStateUpdate(true);
        setSubMenuState(arr);
    }


    return (
        <div className="control-width2">
            {/* Navigation Menu
        ================================================== */}
            <Link className="pa-mobile-main-logo" to="/"><img alt="" src={revoLight} /></Link>

            <nav id="main-nav" className="pa-menu navbar pa-navbar navbar-expand-lg navbar-dark bg-transparent static-top">

                {/* Desktop Menu Logo */}
                <Link className="navbar-brand" to="/"><img alt="" src={revoLight} /></Link>

                {/* The Menu */}
                <ul className="navbar-nav ml-auto">

                    {/* Mobile Menu Logo (only use if "close" buttons are set to false in JS) */}
                    <li data-nav-custom-content className="custom-content pa-mobile-menu-logo">
                        <Link className="pa-mobile-navbar-brand" to="/"><img alt="" src={revoLight} /></Link>
                    </li>

                    {/* Regular Menu Items Start */}
                    <li className="nav-item menu-item-has-children">
                        <Link className="nav-link hvr-underline-from-center" >Discover</Link>
                        <ul className="pa-submenu-ul">
                            <li className="nav-item menu-item-has-children" style={{textAlign: `left`}}>
                                <Link className="nav-link" to="/technology">Technology</Link>
                                <button name="api" onClick={(e) => handleSubmenu(e)}></button>
                                <ul className="pa-sub-submenu-ul">
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology">Index</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/utxo-and-account-balance-models">UTXO and Account Model</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/account-abstraction-layer">Account Abstraction Layer (AAL)</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/evm">Ethereum EVM</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/x86-virtual-machine">x86 Virtual Machine</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/lightning-network">Lightning Network</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/proof-of-stake">Proof of Stake</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/sidechains">Sidechains</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/decentralized-domain-system">Decentralized Domain System</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/technology/decentralized-storage">Decentralized Storage</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to='/ecosystem' >Ecosystem</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><a target="_blank" className="nav-link" href="https://heyzine.com/flip-book/bf81330a53.html">Whitepaper</a></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/members">Team</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/partners">Partners</Link></li>
                        </ul>
                    </li>

                    <li className="nav-item ">
                        <Link className="nav-link hvr-underline-from-center" to="/news">News</Link>
                    </li>

                    <li className="nav-item menu-item-has-children">
                        <Link className="nav-link hvr-underline-from-center">Blockchain</Link>
                        <ul className="pa-submenu-ul">
                            <li className="nav-item menu-item-has-children" style={{textAlign: `left`}}>
                                <a className="nav-link" target="_blank" href="https://mainnet.revo.wtf">Explorer </a>
                                <button name="exp" onClick={(e) => handleSubmenu(e)}></button>
                                <ul className="pa-sub-submenu-ul">
                                    <li className="nav-item" style={ subMenuState[1] ? {opacity: `1`, maxHeight: `100px`} : {}} ><a target="_blank" style={ subMenuState ? {opacity: `1`, maxHeight: `100px`, color: `#00b712`} : {}} className="nav-link" href="https://mainnet.revo.wtf/">Mainnet</a></li>
                                    <li className="nav-item" style={ subMenuState[1] ? {opacity: `1`, maxHeight: `100px`} : {}} ><a target="_blank" style={ subMenuState ? {opacity: `1`, maxHeight: `100px`, color: `#d22b2b`} : {}} className="nav-link" href="https://testnet.revo.wtf/">Testnet</a></li>
                                    <li className="nav-item" style={ subMenuState[1] ? {opacity: `1`, maxHeight: `100px`} : {}} ><a target="_blank" style={ subMenuState ? {opacity: `1`, maxHeight: `100px`, color: `#c9a204`} : {}} className="nav-link" href="https://alpha.revo.wtf/">Alphanet</a></li>
                                </ul>
                            </li>
                            <li className="nav-item menu-item-has-children" style={{textAlign: `left`}}>
                                <a className="nav-link" href="#">Faucets </a>
                                <button name="fau" onClick={(e) => handleSubmenu(e)}></button>
                                <ul className="pa-sub-submenu-ul">
                                    <li className="nav-item" style={ subMenuState[2] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`, color: `#d22b2b`} : {}} className="nav-link" to="/testnet-faucets">Testnet </Link></li>
                                </ul>
                            </li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/network-stats">Network Status </Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/map">Node map </Link></li>
                            <li className="nav-item menu-item-has-children" style={{textAlign: `left`}}>
                                <Link className="nav-link" to="/public-api">API </Link>
                                <button name="api" onClick={(e) => handleSubmenu(e)}></button>
                                <ul className="pa-sub-submenu-ul">
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/public-api">Index</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/block">Block</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/blockchain">Blockchain</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/transaction">Transaction</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/address">Address</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/contract">Contract</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/erc20">ERC20</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/erc721">ERC721</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/pagination-parameters">Pagination</Link></li>
                                    <li className="nav-item" style={ subMenuState[0] ? {opacity: `1`, maxHeight: `100px`} : {}} ><Link style={ subMenuState ? {opacity: `1`, maxHeight: `100px`} : {}} className="nav-link" to="/docs-api/block-timestamp-filters">Block/Timestamp filters</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li className="nav-item menu-item-has-children">
                        <Link className="nav-link hvr-underline-from-center" >App</Link>
                        <ul className="pa-submenu-ul">
                            <li className="nav-item" style={{textAlign: `left`}}><a className="nav-link" target="_blank" href="https://remix.revo.wtf/">IDE</a></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/defi">DeFi</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/domains">Domains</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/notarize-document">Notarize Document</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/create-nft">Create NFT</Link></li>
                        </ul>
                    </li>

                    <li className="nav-item menu-item-has-children">
                        <Link className="nav-link hvr-underline-from-center">Projects</Link>
                        <ul className="pa-submenu-ul">
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/project-docs">Docs</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/develop">Develop</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/build">Build</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/deploy">Deploy</Link></li>
                        </ul>
                    </li>

                    <li className="nav-item menu-item-has-children">
                        <Link className="nav-link hvr-underline-from-center" >Learn</Link>
                        <ul className="pa-submenu-ul">
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/wiki">Wiki</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/tutorials">Tutorials</Link></li>
                            <li className="nav-item" style={{textAlign: `left`}}><Link className="nav-link" to="/training">Training</Link></li>
                        </ul>
                    </li>

                    <li className="nav-item menu-item-has-children">
                        <Link className="nav-link hvr-underline-from-center" >Community</Link>
                        <ul className="pa-submenu-ul">
                            <li className="nav-item" style={{textAlign: `left`}}><a target="_blank" className="nav-link" href="https://forum.revo.wtf/">Forum Hub</a></li>
                            <li className="nav-item" style={{textAlign: `left`}}><a target="_blank" className="nav-link" href="https://forum.revo.wtf/forumdisplay.php?fid=6">Events</a></li>
                            <li className="nav-item" style={{textAlign: `left`}}><a target="_blank" className="nav-link" href="https://forum.revo.wtf/forumdisplay.php?fid=49">Support</a></li>
                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
    )
}
