import React from "react";
import Header from "./Header";
import Mapb from "./content/Mapb";
import { useState, useEffect } from "react";
import axios from 'axios';

export default function NodeMap (){

const [ txt, setTxt ] = useState(false);

useEffect(async () => {
document.title= `Revo | Node Map`;
if(window.location.hostname.slice(0,3) == 'www'){
    window.location.href = 'https://revo.wtf/map';
}
let json = await axios.get("https://revo.wtf/api/map-data/info");
setTxt(json.data);
},[]);


    return (
        <div>
            <Header />
            {txt && <Mapb objectData={txt?.inf} nodeData={txt?.res}  />}
            <h6 className="button-map-text" style={ window.innerWidth > 1200 ? {position: `absolute`,color: `white`, bottom: `0`, left: `0`, right: `0`} : {opacity: 0} }>This page does not represent the entire state of the REVO blockchain network, but shows only nodes connected to our seednode.</h6>
        </div>
    )
}
