import axios from 'axios';


export function getArticles(){ 
    return async (dispatch)=>{
        let json = await axios.post('https://revo.wtf/api/article/info');
        return dispatch({
            type: 'GET_ARTICLES',
            payload: json.data
        })        
    }    
}

export function homeLoader(){
    return async (dispatch) => {
        let homeState = true;
        return dispatch({
            type: 'HOME_LOADED',
            payload: homeState
        });        
    }
}
