import React from 'react';
import '../styles/Home.css'
import { useEffect, useState } from 'react';
import Header from './Header';
import HomeContent from './content/HomeContent';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { homeLoader } from '../actions';


export default function Home() {    
    const dispatch = useDispatch();
    const homeLoaded = useSelector((state) => state.homeLoaded);

    const articles = useSelector((state) => state.articles);


    useEffect(async () => {        
        document.title= `Revo | Blockchain Technology`
        if (window.location.hostname.slice(0, 3) == 'www') {
            window.location.href = 'https://revo.wtf/';
        }
    }, []);
    
    
    setTimeout(function() {
        if(!homeLoaded){
            dispatch(homeLoader());
        }
   }, 1000);

    return (
        <div className='div-main-home'>
        <div style={homeLoaded || articles.length ? {opacity: `0`, display: `none`} : {}} className="pa-loader">
            <section className="warper"><div className="spinn" style={{opacity: `0.24`}}><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div><div className="preloader"><p className="hello"></p><div id="preloader"><div id="loader"></div></div></div></section>
        </div>
            <Header />
            <HomeContent />
            <Footer />
        </div>
    )
}
