import React from 'react';
import { useEffect, useState } from 'react';
import NetworkContent from "./content/NetworkContent";
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';


export default function NetworkStats() {
    

    const [ txt, setTxt ] = useState(false);
	const [ipData, setIpData] = useState(false);

    useEffect(async () => {        
        document.title= `Revo | Network Status`
        if (window.location.hostname.slice(0, 3) == 'www') {
            window.location.href = 'https://revo.wtf/';
        }
        let json = await axios.get("https://revo.wtf/api/map-data/info");

        
		let ipJson = await axios.get("https://revo.wtf/get/ip");
		if(ipJson.data){
			setIpData(ipJson.data);
		}		
        setTxt(json.data);
    }, []);

    const [loaded, setLoaded] = useState(false);

    return (
        <div>
        <div style={loaded ? { opacity: `0`, display: `none` } : {}} className="pa-loader">
            <section className="warper"><div className="spinn" style={{ opacity: `0.24` }}><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div><div className="preloader"><p className="hello"></p><div id="preloader"><div id="loader"></div></div></div></section>
        </div>
            <Header />
            {txt && ipData && <NetworkContent objectData={txt?.inf} nodeData={txt?.res} setLoaded={setLoaded} ipData={ipData} />}
            <Footer />
        </div>
    )
}
