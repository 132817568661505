import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "../../styles/assets/css/particle-theme.css"
import 'mapbox-gl/dist/mapbox-gl.css';
import "../../styles/Map.css";
import axios from 'axios';

mapboxgl.accessToken = 'pk.eyJ1IjoicmV2b25ldHdvcmsiLCJhIjoiY2t4NjRwOTQ1MmJkOTJ5cHpjYXI3ZnNpYSJ9.t7W6_OJEpRMs0bdODE28yA';

export default function Mapb({ objectData, nodeData }) {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng, setLng] = useState(12.808945);
	const [lat, setLat] = useState(41.312604);
	const [zoom, setZoom] = useState(4);

	const [ipData, setIpData] = useState(false);


	useEffect(async () => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/revonetwork/ckx6io2zq7umj14pce93ns60q',
			center: [lng, lat],
			zoom: zoom,
			dragRotate: false,
			projection: 'globe',
		});
		setMapState([true, false]);
		
		let json = await axios.get("https://revo.wtf/get/ip");
		if(json.data){
			setIpData(json.data);
		}		
	},[]);

	const [mapState, setMapState] = useState([false, false]);


	function getFormattedDate(date) {
		var today = new Date();
		var diffTime = Math.abs(today - date);//lastBlockCreated);
		var diffDays = Math.trunc(diffTime / (1000 * 60 * 60 * 24));
		var diffHours = Math.trunc(diffTime / (1000 * 60 * 60));
		var diffMins = Math.trunc(diffTime / 1000 / 60);

		var s = "";
		if (diffDays > 0)
			s = diffDays + "d " + (diffHours % 24) + "h ";
		else if (diffHours > 0)
			s = diffHours + " hours";
		else
			s = diffMins + " mins";

		return s;
	}


	async function nodeMapAdd() {
		
		nodeData.map(async (e) => {
			await marquer(e);
		}
		);
		setMapState([true, true]);
	}

	const [ lngLatCheck, setLngLatCheck ] = useState([]);

	

	async function marquer(e) {
		const ipArr = ["217.133.221.236", "217.133.221.236", "217.133.221.237", "217.133.221.238", "217.133.221.239", "217.133.221.240", "217.133.221.241"]
		let target = e.addr.includes('seed') ? e.addr.slice(4,5) == "." ? ipArr[0] : ipArr[parseInt(e.addr.slice(4,5))] : e.addr.split(":")[0];
		let currentIpData = ipData.find(e => e.addr == target);
		let twiceLngLatVerify = lngLatCheck.map(e => {
			if(e[0] == currentIpData.location.longitude && e[1] == currentIpData.location.latitude){	
				return true
			}
		})
		setLngLatCheck([...lngLatCheck,[currentIpData.location.longitude, currentIpData.location.latitude]]);		
		let twiceIpVerify = nodeData.filter(elem => elem.addr.split(":")[0] == e.addr.split(":")[0]).length > 1
		let lng = twiceIpVerify || twiceLngLatVerify ? currentIpData.location.longitude + parseFloat((Math.random()/100).toFixed(3)) : currentIpData.location.longitude;
		let lat = twiceIpVerify || twiceLngLatVerify ? currentIpData.location.latitude + parseFloat((Math.random()/100).toFixed(3)) : currentIpData.location.latitude;
		let isp = currentIpData.traits.isp;
		e.synced_headers = e.synced_headers == "-1" ? '<i style="color:yellow" class="fa fa-spinner" aria-hidden="true"></i>' : e.synced_headers;
		e.synced_blocks = e.synced_blocks == "-1" ? '<i style="color:yellow" class="fa fa-spinner" aria-hidden="true"></i>' : e.synced_blocks;
		let corename = e.subver.replaceAll('/', '').split("(")[0];
		let uacomment = e.subver.substring(e.subver.indexOf("(") + 1, e.subver.indexOf(")"));
		uacomment = uacomment == "" ? "<font color='orange'>[default]</font>" : "<font color='#00e600'><b>[" + uacomment + "]</b></font>";
		let tmp_date = new Date(e.conntime * 1000);
		let nodeStatus = e.synced_headers == "-1" ? `<font color="yellow">pending</font>` : `<font color="#00e600">synced</font>`;
		const el = document.createElement('div');
		el.className = 'marker';
		el.key = e.addr
		new mapboxgl.Marker(el, { anchor: 'center' })
			.setLngLat([lng, lat])
			.setPopup(
				new mapboxgl.Popup({ offset: 15 }) // add popups
					.setHTML(
						`	
				<div>
				<img style="display: inline; margin-left:-10px; margin-top:-10px;" height="15px"src="https://www.svgrepo.com/show/29744/servers.svg"><p style="display:inline;"">${uacomment}</p>
				<br><h5 style="color:#3498DB; display: inline;">${corename}</h4><font style="display:inline;" color="#3498DB"> v. ${e.version}</font>					
				<hr style="margin-top:-3px;"></hr>	
				<table style="margin-top:-10px;"> 
				  <tr>
					<td><b><i style="color:#D0D0D0" class="fa fa fa-file-text" aria-hidden="true"></i><font color="#D0D0D0"> Headers</font></b></td>
					<td><b><i style="color:#D0D0D0" class="fa fa fa-cube" aria-hidden="true"></i><font color="#D0D0D0"> Blocks</font></b></td>
					<td><b><i style="color:#D0D0D0" class="fa fa fa-microchip" aria-hidden="true"></i><font color="#D0D0D0"> Relay</font></b></td>
					<td><b><i style="color:#D0D0D0" class="fa fa fa-link" aria-hidden="true"></i><font color="#D0D0D0"> Listen</font></b></td>
				  </tr>
				  <tr>
					<td><font color="#A9A9A9">${e.synced_headers}</font></td>
					<td><font color="#A9A9A9">${e.synced_blocks}</font></td>
					<td><font color="#00e600">${e.relaytxes}</font></td>
					<td><font color="#00e600">${e.inbound}</font></td>
				  </tr>
				</table>
				<table> 
				  <tr>
					<td><b><i style="color:#D0D0D0" class="fa fa-download" aria-hidden="true"></i><font color="#D0D0D0"> Received</font></b></td>
					<td><b><i style="color:#D0D0D0" class="fa fa-upload" aria-hidden="true"></i><font color="#D0D0D0"> Sent</font></b></td>
					<td><b><i style="color:#D0D0D0" class="fa fa-globe" aria-hidden="true"></i><font color="#D0D0D0"> ISP</font></b></td>
				  </tr>
				  <tr>
					<td><font color="#A9A9A9">${formatBytes(e.bytessent)}</font></td>
					<td><font color="#A9A9A9">${formatBytes(e.bytesrecv)}</font></td>
					<td><font color="#A9A9A9">${isp}</font></td>
				  </tr>
				</table>
				<hr style="margin-top:-1px;"></hr>									
				<p style="margin-top:-7px;">
				<font style="font-family: 'Open Sans', sans-serif; color:#D0D0D0;" size="2"><i style="color:#D0D0D0" class="fa fa-globe" aria-hidden="true"></i><b><font color="#D0D0D0"> Status: </b></font><font style="font-family: 'Open Sans', sans-serif;" size="2" >${nodeStatus}</font><br>
				<font style="font-family: 'Open Sans', sans-serif; color:#D0D0D0;" size="2"><b><i style="color:#D0D0D0" class="fa fa-clock-o" aria-hidden="true"></i> Connected since: </b></font><font color="#A9A9A9">${getFormattedDate(tmp_date)}</font>
				</p>		
				</div>					
				
			`
					)
			)
			.addTo(map.current);


	}

	mapState[0] && !mapState[1] && ipData && nodeMapAdd();

	const [blockInfo, setBlockInfo] = useState({});
	const [checked, setChecked] = useState(false);


	const getBlockInfo = async () => {
		setChecked(true);
		let json = await axios("https://revo.wtf/api/map-data/info");
		setBlockInfo(json);

	}

	!checked && getBlockInfo();

	useEffect(() => {
		if (!map.current) return; // wait for map to initialize
		map.current.on('move', () => {
			setLng(map.current.getCenter().lng.toFixed(4));
			setLat(map.current.getCenter().lat.toFixed(4));
			setZoom(map.current.getZoom().toFixed(2));
		});


	}, []);


	function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	return (
		<div>
			{/*<div className="sidebar">
Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
</div>*/}
			<div ref={mapContainer} className="map-container" ></div>

			<div id="bottom_nav">

				&nbsp;&nbsp;<font style={{ fontFamily: 'Open Sans, sans-serif', color: `white` }} size="3"><b> Global Network</b></font><br></br>
				&nbsp;&nbsp;&nbsp; <font style={{ fontFamily: 'Open Sans, sans-serif', color: `#D0D0D0` }} size="2"><i style={{ color: `D0D0D0` }} className="fa fa-globe" aria-hidden="true"></i><b> Node: </b></font><font style={{ fontFamily: `Open Sans, sans-serif` }} size="2" color="#00e600">synced</font><br></br>
				&nbsp;&nbsp;&nbsp; <font style={{ fontFamily: 'Open Sans, sans-serif', color: `#D0D0D0` }} size="2"><i style={{ color: `D0D0D0` }} className="fa fa fa-file-text" aria-hidden="true"></i><b> Headers: </b></font><font id="totalheaders" style={{ fontFamily: `Open Sans, sans-serif`, color: `#A9A9A9` }} size="2"> {objectData.headers}</font><br></br>
				&nbsp;&nbsp;&nbsp; <font style={{ fontFamily: 'Open Sans, sans-serif', color: `#D0D0D0` }} size="2"><i style={{ color: `D0D0D0` }} className="fa fa fa-cube" aria-hidden="true"></i><b> Blocks: </b></font><font id="totalblocks" style={{ fontFamily: `Open Sans, sans-serif`, color: `#A9A9A9` }} size="2">{objectData.blocks}</font><br></br>
				&nbsp;&nbsp;&nbsp; <font style={{ fontFamily: 'Open Sans, sans-serif', color: `#D0D0D0` }} size="2"><i style={{ color: `D0D0D0` }} className="fa fa-globe" aria-hidden="true"></i><b> Peers: </b></font><font id="totalpeers" style={{ fontFamily: `Open Sans, sans-serif` }} size="2" color="#00e600">{nodeData.length + 26}</font>
			</div>
			
		</div>
	);
}

